import React from 'react'
import BlockContent from '../components/block-content'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'

export const query = graphql`
  query FaqQuestionQuery($id: String!) {
    question: sanityFaqQuestion(id: {eq: $id}) {
      question
      slug {
        current
      }
      answer: _rawAnswer(resolveReferences:{maxDepth:100})
      category {
        title
        slug {
          current
        }
      }
    }
  }
`

const faqQuestionTemplate = (props) => {
  const { data, errors} = props
  const question = data && data.question

  return (
    <>
      <SEO title={question.title}/>
      <div className='header-offset'>
        <div className='p-5 pb-20 lg:px-12 xl:px-20 max-w-screen-md mx-auto'>
          <Link to='/kundeservice'>&larr; Tilbake til kundeservice</Link>
          <h1 className='h2 mt-8 mb-8'>{question.question}</h1>
          <BlockContent blocks={question.answer} className='editorial' />
        </div>
      </div>
    </>
  )
}

export default faqQuestionTemplate
